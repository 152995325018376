/** all frame report card */
.six_months_bonus_report_card_container {
  position: relative;
  width: 2480px;
  height: 3508px;

  .report_frame {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }

  .bonus_info_container{
    z-index: 25;
    .bonus_month {
      position: absolute;
      z-index: inherit;
      font-family: 'AbrilFatface_Regular';
      font-size: 83px;
      top: 10.5%;
      left: 19.8%;
      color: #dcb444;
      font-weight: 400;
      width: 1500px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bonus_level {
      position: absolute;
      z-index: inherit;
      font-family: 'Berlin_Sans_Regular';
      font-size: 89px;
      text-transform: uppercase;
      color: #000000;
      top: 61.6%;
      left: -6.2%;
      // font-weight: 400;
      word-spacing: 0.1rem;
      width: 1500px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .agent_profile {
      position: absolute;
      z-index: -1;
      width: 880px;
      height: 1336px;
      object-fit: cover;
      top: 6.8%;
      left: 32.3%;
    }

    // .bonus_less_ten_products {
    //   z-index: 25;
    //   // .agent_profile {
    //   //   position: absolute;
    //   //   z-index: -1;
    //   //   width: 784px;
    //   //   height: 1140px;
    //   //   top: 18.5%;
    //   //   left: 34.2%;
    //   // }

    //   // .agent_profile_container {
    //   //   position: absolute;
    //   //   z-index: -1;
    //   //   width: 784px;
    //   //   height: 1140px;
    //   //   top: 18.5%;
    //   //   left: 34.2%;
    //   //   .agent_profile {
    //   //     object-fit: cover;
    //   //     width: 100%;
    //   //     height: 100%;
    //   //   }
    //   // }

    //   .agent_code {
    //     position: absolute;
    //     z-index: inherit;
    //     height: auto;
    //     top: 31.6%;
    //     left: 60.3%;
    //     color: #000000;
    //     font-family: 'Arimo_regular';
    //     font-size: 86px;
    //     display: flex;
    //     justify-content: center;
    //     text-align: center;
    //     font-weight: 400;
    //     &.prefix_name {
    //       top: 35%;
    //       left: 18.9%;
    //       font-size: 66px;
    //     }
    //   }

    //   .agent_name {
    //     position: absolute;
    //     z-index: inherit;
    //     display: flex;
    //     top: 27.7%;
    //     left: 21%;
    //     width: 150rem;
    //     height: 11rem;
    //     align-items: center;
    //     justify-content: center;
    //     color: #000000;
    //     font-family: 'Beautyful_comethrue_bold';
    //     font-weight: 600;
    //     font-size: 132px;
    //     &.mm_name {
    //       left: 20.5%;
    //       font-size: 115px;
    //       font-family: 'BMKing001-Bold';
    //     }
    //     &.more_ten {
    //       font-size: 120px;
    //     }
    //     &.more_eighteen {
    //       font-size: 96px;
    //     }
    //   }

    //   .bonus_award {
    //     position: absolute;
    //     z-index: inherit;
    //     top: 36.2%;
    //     left: 43.7%;
    //     font-size: 77px;
    //     width: 80rem;
    //     height: 140px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     font-family: 'BMKing001-Bold';
    //     color: #ffffff;
    //     .award_amount {
    //       letter-spacing: 0.3rem;
    //     }
    //   }

    //   .bonus_product_list {
    //     position: absolute;
    //     z-index: inherit;
    //     top: 41.5%;
    //     left: 31.7%;
    //     display: flex;
    //     flex-direction: column;
    //     width: 1900px;
    //     height: 65rem;
    //     font-family: 'Ace';
    //     color: #000000;
    //     font-weight: 400;
    //     // row-gap: 9px;
    //     line-height: 85px;
    //     .product_info_row {
    //       left: 10%;
    //       width: 100%;
    //       display: grid;
    //       grid-template-columns: 45% 55%;
    //       .product_name {
    //         width: 100%;
    //         text-align: right;
    //       }
    //     }
    //   }
    // }

    .bonus_more_ten_products {
      z-index: 25;
      // .agent_profile {
      //   position: absolute;
      //   z-index: -1;
      //   width: 714px;
      //   height: 1033px;
      //   top: 18.3%;
      //   left: 35.5%;
      // }
      // .agent_profile_container {
      //   position: absolute;
      //   z-index: -1;
      //   width: 714px;
      //   height: 1033px;
      //   top: 18.3%;
      //   left: 35.5%;
      //   .agent_profile {
      //     object-fit: cover;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
      .agent_level {
        position: absolute;
        z-index: inherit;
        font-size: 90px;
        font-family: 'Poppins_bold';
        width: 759px;
        height: 338px;
        color: #e4b13f;
        top: 20%;
        left: 3%;
        text-shadow: rgb(255, 255, 255) 15px 0px 0px, rgb(255, 255, 255) 14.9667px 0.999259px 0px,
          rgb(255, 255, 255) 14.8669px 1.99408px 0px, rgb(255, 255, 255) 14.701px 2.98004px 0px,
          rgb(255, 255, 255) 14.4698px 3.95276px 0px, rgb(255, 255, 255) 14.1744px 4.90792px 0px,
          rgb(255, 255, 255) 13.8159px 5.84128px 0px, rgb(255, 255, 255) 13.3961px 6.74868px 0px,
          rgb(255, 255, 255) 12.9168px 7.6261px 0px, rgb(255, 255, 255) 12.38px 8.46964px 0px,
          rgb(255, 255, 255) 11.7883px 9.27555px 0px, rgb(255, 255, 255) 11.1442px 10.0402px 0px,
          rgb(255, 255, 255) 10.4506px 10.7603px 0px, rgb(255, 255, 255) 9.71056px 11.4326px 0px,
          rgb(255, 255, 255) 8.92738px 12.0541px 0px, rgb(255, 255, 255) 8.10453px 12.6221px 0px,
          rgb(255, 255, 255) 7.24568px 13.1339px 0px, rgb(255, 255, 255) 6.35464px 13.5874px 0px,
          rgb(255, 255, 255) 5.43537px 13.9806px 0px, rgb(255, 255, 255) 4.49194px 14.3116px 0px,
          rgb(255, 255, 255) 3.52856px 14.5791px 0px, rgb(255, 255, 255) 2.54951px 14.7817px 0px,
          rgb(255, 255, 255) 1.55912px 14.9188px 0px, rgb(255, 255, 255) 0.561813px 14.9895px 0px,
          rgb(255, 255, 255) -0.437993px 14.9936px 0px, rgb(255, 255, 255) -1.43585px 14.9311px 0px,
          rgb(255, 255, 255) -2.42733px 14.8023px 0px, rgb(255, 255, 255) -3.40803px 14.6077px 0px,
          rgb(255, 255, 255) -4.37359px 14.3482px 0px, rgb(255, 255, 255) -5.31971px 14.025px 0px,
          rgb(255, 255, 255) -6.2422px 13.6395px 0px, rgb(255, 255, 255) -7.13696px 13.1933px 0px,
          rgb(255, 255, 255) -8.00001px 12.6886px 0px, rgb(255, 255, 255) -8.82752px 12.1274px 0px,
          rgb(255, 255, 255) -9.6158px 11.5124px 0px, rgb(255, 255, 255) -10.3614px 10.8463px 0px,
          rgb(255, 255, 255) -11.0609px 10.1319px 0px, rgb(255, 255, 255) -11.7113px 9.37259px 0px,
          rgb(255, 255, 255) -12.3097px 8.5716px 0px, rgb(255, 255, 255) -12.8533px 7.73252px 0px,
          rgb(255, 255, 255) -13.3399px 6.85909px 0px, rgb(255, 255, 255) -13.7672px 5.95518px 0px,
          rgb(255, 255, 255) -14.1333px 5.02482px 0px, rgb(255, 255, 255) -14.4367px 4.07214px 0px,
          rgb(255, 255, 255) -14.6759px 3.10136px 0px, rgb(255, 255, 255) -14.8499px 2.1168px 0px,
          rgb(255, 255, 255) -14.9579px 1.12284px 0px, rgb(255, 255, 255) -14.9995px 0.123888px 0px,
          rgb(255, 255, 255) -14.9744px -0.875612px 0px,
          rgb(255, 255, 255) -14.8828px -1.87122px 0px, rgb(255, 255, 255) -14.7251px -2.85852px 0px,
          rgb(255, 255, 255) -14.502px -3.83312px 0px, rgb(255, 255, 255) -14.2144px -4.79068px 0px,
          rgb(255, 255, 255) -13.8637px -5.72697px 0px, rgb(255, 255, 255) -13.4514px -6.63781px 0px,
          rgb(255, 255, 255) -12.9793px -7.51916px 0px, rgb(255, 255, 255) -12.4496px -8.3671px 0px,
          rgb(255, 255, 255) -11.8645px -9.17787px 0px, rgb(255, 255, 255) -11.2268px -9.94786px 0px,
          rgb(255, 255, 255) -10.5391px -10.6737px 0px, rgb(255, 255, 255) -9.80465px -11.352px 0px,
          rgb(255, 255, 255) -9.02663px -11.98px 0px, rgb(255, 255, 255) -8.20851px -12.5547px 0px,
          rgb(255, 255, 255) -7.35391px -13.0736px 0px, rgb(255, 255, 255) -6.46665px -13.5345px 0px,
          rgb(255, 255, 255) -5.55065px -13.9352px 0px, rgb(255, 255, 255) -4.60999px -14.274px 0px,
          rgb(255, 255, 255) -3.64885px -14.5494px 0px, rgb(255, 255, 255) -2.67151px -14.7602px 0px,
          rgb(255, 255, 255) -1.68229px -14.9054px 0px,
          rgb(255, 255, 255) -0.685596px -14.9843px 0px,
          rgb(255, 255, 255) 0.314142px -14.9967px 0px, rgb(255, 255, 255) 1.31248px -14.9425px 0px,
          rgb(255, 255, 255) 2.305px -14.8218px 0px, rgb(255, 255, 255) 3.28727px -14.6354px 0px,
          rgb(255, 255, 255) 4.25493px -14.3839px 0px, rgb(255, 255, 255) 5.20369px -14.0685px 0px,
          rgb(255, 255, 255) 6.12934px -13.6906px 0px, rgb(255, 255, 255) 7.02775px -13.2518px 0px,
          rgb(255, 255, 255) 7.89494px -12.7542px 0px, rgb(255, 255, 255) 8.72705px -12.1999px 0px,
          rgb(255, 255, 255) 9.52039px -11.5915px 0px, rgb(255, 255, 255) 10.2714px -10.9315px 0px,
          rgb(255, 255, 255) 10.9768px -10.223px 0px, rgb(255, 255, 255) 11.6335px -9.469px 0px,
          rgb(255, 255, 255) 12.2384px -8.67297px 0px, rgb(255, 255, 255) 12.789px -7.83842px 0px,
          rgb(255, 255, 255) 13.2828px -6.96903px 0px, rgb(255, 255, 255) 13.7175px -6.06869px 0px,
          rgb(255, 255, 255) 14.0914px -5.14138px 0px, rgb(255, 255, 255) 14.4026px -4.19123px 0px,
          rgb(255, 255, 255) 14.6498px -3.22246px 0px, rgb(255, 255, 255) 14.8319px -2.23938px 0px,
          rgb(255, 255, 255) 14.9481px -1.24634px 0px, rgb(255, 255, 255) 14.998px -0.247768px 0px;
      }
      .agent_code {
        position: absolute;
        z-index: inherit;
        // width: 180px;
        height: auto;
        top: 46.9%;
        left: 41.3%;
        color: #22313e;
        font-family: 'poppins_regular';
        font-size: 90px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: 600;
        letter-spacing: -5px;
        &.prefix_name {
          top: 33.7%;
          left: 20%;
          font-size: 66px;
        }
      }

      .agent_name {
        position: absolute;
        z-index: inherit;
        display: flex;
        top: 41.5%;
        left: 1.5%;
        width: 150rem;
        height: 11rem;
        align-items: center;
        justify-content: center;
        color: #22313e;
        font-family: 'BerkshireSwash-Regular';
        font-weight: 700;
        font-size: 146px;
        letter-spacing: 4px;
        &.mm_name {
          top: 41.5%;
          left: 1.5%;
          font-size: 135px;
          color: #22313e;
          font-family: 'BerkshireSwash-Regular';
          font-weight: 700;
        }
        &.more_ten {
          font-size: 130px;
        }
        &.more_eighteen {
          font-size: 125px;
        }
      }

      .bonus_award {
        position: absolute;
        z-index: inherit;
        top: 52%;
        left: 30.7%;
        font-size: 90px;
        width: 65rem;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'BMKing001-Regular';
        color: #ffffff;
        .award_amount {
          letter-spacing: 0.3rem;
        }
      }

      .bonus_product_list {
        position: absolute;
        z-index: inherit;
        top: 58%;
        left: 12.7%;
        display: flex;
        flex-direction: column;
        // row-gap: 2px;
        width: 1900px;
        height: 73rem;
        font-family: 'BMKing001-Regular';
        color: #22313e;
        // font-weight: 400;
        line-height: 67px;
        .product_info_row {
          left: 10%;
          width: 100%;
          display: grid;
          grid-template-columns: 45% 55%;
          .product_name {
            width: 100%;
            text-align: right;
          }
        }
        &.less_twel{
          line-height: 75px;
        }
        &.less_seventeen {
          line-height: 68px;
        }
        &.less_twenty {
          // top: 59.5%;
          line-height: 60px;
        }
        &.more_twenty {
          // top: 59.2%;
          line-height: 50px;
        }
      }
    }
  }

  // .bonus_info_container_th {
  //   z-index: 25;
  //   .bonus_month {
  //     position: absolute;
  //     z-index: inherit;
  //     font-family: 'Arno_pro';
  //     font-size: 83px;
  //     top: 10.5%;
  //     left: 20%;
  //     color: #4e0000;
  //     font-weight: 400;
  //     letter-spacing: 2px;
  //     text-transform: uppercase;
  //     width: 1500px;
  //     height: 90px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .bonus_level {
  //     position: absolute;
  //     z-index: inherit;
  //     font-family: 'Arno_pro';
  //     font-size: 73px;
  //     text-transform: uppercase;
  //     color: #5a0000;
  //     letter-spacing: 1px;
  //     top: 12.5%;
  //     left: 20%;
  //     font-weight: 400;
  //     width: 1500px;
  //     height: 100px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //   }

  //   .bonus_less_six_products {
  //     z-index: 25;
  //     .agent_profile {
  //       position: absolute;
  //       z-index: -1;
  //       width: 781px;
  //       height: 1058px;
  //       top: 17.2%;
  //       left: 34.3%;
  //     }

  //     .agent_code {
  //       position: absolute;
  //       z-index: inherit;
  //       width: 180px;
  //       height: auto;
  //       top: 36.8%;
  //       left: 18.2%;
  //       color: #dbb343;
  //       font-family: 'AbrilFatface_Regular';
  //       font-size: 41px;
  //       display: flex;
  //       justify-content: center;
  //       text-align: center;
  //       font-weight: 400;
  //       &.prefix_name {
  //         top: 34.4%;
  //         left: 18.3%;
  //         font-size: 72px;
  //       }
  //     }

  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 52.2%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #871f24;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       font-size: 132px;
  //       &.mm_name {
  //         top: 52.7%;
  //         font-size: 100px;
  //       }
  //       &.more_twenty_three {
  //         top: 52.6%;
  //         font-size: 125px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 58.9%;
  //       left: 30.6%;
  //       font-size: 85px;
  //       width: 60rem;
  //       height: 210px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 66%;
  //       left: 12.4%;
  //       display: flex;
  //       flex-direction: column;
  //       width: 1900px;
  //       height: 49rem;
  //       font-family: 'Ace';
  //       color: #5e1616;
  //       font-weight: 600;
  //       line-height: 75px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //     }
  //   }

  //   .bonus_more_six_products {
  //     z-index: 25;
  //     .agent_profile {
  //       position: absolute;
  //       z-index: -1;
  //       width: 709px;
  //       height: 1033px;
  //       top: 15.5%;
  //       left: 35.4%;
  //     }

  //     .agent_code {
  //       position: absolute;
  //       z-index: inherit;
  //       width: 180px;
  //       height: auto;
  //       top: 34.6%;
  //       left: 18.3%;
  //       color: #dcb444;
  //       font-family: 'AbrilFatface_Regular';
  //       font-size: 40px;
  //       display: flex;
  //       justify-content: center;
  //       text-align: center;
  //       font-weight: 400;
  //       &.prefix_name {
  //         top: 32.4%;
  //         left: 18.4%;
  //         font-size: 66px;
  //       }
  //     }

  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 46.4%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #871f24;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       font-size: 132px;
  //       &.mm_name {
  //         top: 46.9%;
  //         font-size: 90px;
  //       }
  //       &.more_twenty_three {
  //         font-size: 125px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 52%;
  //       left: 30.6%;
  //       font-size: 85px;
  //       width: 60rem;
  //       height: 210px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 59%;
  //       left: 12.5%;
  //       display: flex;
  //       flex-direction: column;
  //       width: 1900px;
  //       height: 68rem;
  //       font-family: 'Ace';
  //       color: #5e1616;
  //       font-weight: 600;
  //       line-height: 71px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //       &.less_seventeen {
  //         line-height: 60px;
  //       }
  //       &.less_twenty {
  //         top: 58.4%;
  //         line-height: 53px;
  //       }
  //       &.more_twenty {
  //         top: 58%;
  //         line-height: 49px;
  //       }
  //     }
  //   }
  // }
}

/** trip all frame report card */
.six_months_trip_card_container {
  position: relative;
  width: 2480px;
  height: 3508px;

  .report_frame {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }

  .bonus_info_container {
    z-index: 25;

    .agent_profile_container {
      position: absolute;
      z-index: -1;
      width: 927px;
      height: 1300px;
      top: 0%;
      left: 30.9%;
      .agent_profile {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .agent_name {
      position: absolute;
      z-index: inherit;
      display: flex;
      top: 35%;
      left: 3%;
      width: 150rem;
      height: 11rem;
      align-items: center;
      justify-content: center;
      color: #1e8164;
      font-family: 'AbrilFatface_Regular';
      font-weight: 400;
      font-size: 136px;
      // &.char-9 {
      //   font-size: 90px;
      // }
      // &.char-15 {
      //   font-size: 65px;
      // }
      // &.mm_name {
      //   font-size: 90px;
      // }
    }
    .agent_code {
      position: absolute;
      z-index: inherit;
      // width: 180px;
      height: auto;
      top: 40%;
      left: 41.4%;
      color: #1e8164;
      font-family: 'AbrilFatface_Regular';
      font-size: 79px;
      display: flex;
      justify-content: center;
      text-align: center;
      font-weight: 400;
      letter-spacing: 2px;
      &.prefix_name {
        top: 32.5%;
        left: 20.3%;
        font-size: 91px;
        font-weight: 700;
      }
    }

    .bonus_month {
      position: absolute;
      z-index: inherit;
      font-family: 'AbrilFatface_Regular';
      font-size: 83px;
      top: 11.2%;
      left: 34.3%;
      color: #025f92;
      font-weight: 400;
    }

    .bonus_award {
      position: absolute;
      z-index: inherit;
      top: 51.6%;
      left: 23.6%;
      font-size: 107px;
      width: 80rem;
      height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'bmking010_bold';
      font-weight: bold;
      color: #1e8164;
      .award_amount {
        letter-spacing: 0.3rem;
      }
    }

    .bonus_product_list {
      position: absolute;
      z-index: inherit;
      top: 57%;
      left: 7%;
      display: flex;
      flex-direction: column;
      width: 2200px;
      height: 65rem;
      font-size: 58px;
      font-family: 'Ace';
      color: #1e8164;
      line-height: 80px;
      .product_info_row {
        left: 10%;
        width: 100%;
        display: grid;
        grid-template-columns: 45% 55%;
        .product_name {
          width: 100%;
          text-align: right;
        }
      }
      &.more_twelve {
        font-size: 54px;
        line-height: 60px;
      }
    }

  }

  // .bonus_info_container_th {
  //   z-index: 25;
  //   .bonus_month {
  //     position: absolute;
  //     z-index: inherit;
  //     font-family: 'AbrilFatface_Regular';
  //     font-size: 83px;
  //     top: 10.5%;
  //     left: 33.9%;
  //     color: #00706f;
  //     font-weight: 400;
  //   }

  //   .agent_code {
  //     position: absolute;
  //     z-index: inherit;
  //     width: 180px;
  //     height: auto;
  //     top: 29.1%;
  //     left: 19.2%;
  //     color: #f8d955;
  //     font-family: 'AbrilFatface_Regular';
  //     font-size: 59px;
  //     display: flex;
  //     justify-content: center;
  //     text-align: center;
  //     font-weight: 400;
  //     &.prefix_name {
  //       top: 25.9%;
  //       left: 19.3%;
  //       font-size: 103px;
  //       font-weight: 400;
  //     }
  //   }

  //   .agent_profile_container {
  //     position: absolute;
  //     z-index: -1;
  //     width: 807px;
  //     height: 998px;
  //     top: 14.6%;
  //     left: 33.7%;
  //     .agent_profile {
  //       object-fit: cover;
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }

  //   .bonus_less_six_products {
  //     z-index: 25;
  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 46.4%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #007575;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       &.mm_name {
  //         top: 46.4%;
  //         font-size: 110px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 53.7%;
  //       left: 22.4%;
  //       font-size: 90px;
  //       width: 85rem;
  //       height: 200px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 61.5%;
  //       left: 8%;
  //       display: flex;
  //       flex-direction: column;
  //       width: 2200px;
  //       height: 65rem;
  //       font-family: 'Ace';
  //       color: #439087;
  //       line-height: 72px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //     }
  //   }

  //   .bonus_more_six_products {
  //     z-index: 25;
  //     .agent_name {
  //       position: absolute;
  //       z-index: inherit;
  //       display: flex;
  //       top: 45%;
  //       left: 1.5%;
  //       width: 150rem;
  //       height: 11rem;
  //       align-items: center;
  //       justify-content: center;
  //       color: #007575;
  //       font-family: 'AbrilFatface_Regular';
  //       font-weight: 400;
  //       &.mm_name {
  //         top: 45.1%;
  //         font-size: 110px;
  //       }
  //     }

  //     .bonus_award {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 50.7%;
  //       left: 22.3%;
  //       font-size: 80px;
  //       width: 86rem;
  //       height: 200px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       font-family: 'BMKING002_Regular';
  //       color: #ffffff;
  //       .award_amount {
  //         letter-spacing: 0.3rem;
  //       }
  //     }

  //     .bonus_product_list {
  //       position: absolute;
  //       z-index: inherit;
  //       top: 57.4%;
  //       left: 8%;
  //       display: flex;
  //       flex-direction: column;
  //       // row-gap: 2px;
  //       width: 2200px;
  //       height: 77rem;
  //       font-family: 'Ace';
  //       color: #439087;
  //       font-weight: 500;
  //       line-height: 70px;
  //       .product_info_row {
  //         left: 10%;
  //         width: 100%;
  //         display: grid;
  //         grid-template-columns: 45% 55%;
  //         .product_name {
  //           width: 100%;
  //           text-align: right;
  //         }
  //       }
  //     }
  //     &.more_fifteen_products {
  //       .bonus_product_list {
  //         top: 57.4%;
  //         left: 8%;
  //         line-height: 65px;
  //       }
  //     }
  //   }
  // }
}
